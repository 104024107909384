<template>
    <div class="slide slide--radioGuide">
        <div class="slide__image">
            <figure>
                <img src="https://media-cdn.1twente.nl/Assets/studio-twente-fm.jpeg" alt="">
            </figure>
            <div class="slide__title">
                <h1>Vandaag op Twente FM</h1>
            </div>
            <div class="slide__date">
                <span>{{ currentDayLocale }}</span>
            </div>
        </div>

        <div class="slide__body">
            <!-- block for radioguide twentefm -->
            <div class="slide__station">
                <div class="slide__station--title">
                    Twente FM <span>(Luister je nu)</span>
                </div>
                <div class="slide__station--programs">
                    <div class="slide__program" v-for="program in radioGuideTwenteFMFromApi" :key="program.title">
                        <div class="slide__program--time">
                            {{ program.startDate.slice(11, 16) }}
                        </div>
                        <div class="slide__program--title">
                            {{ program.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios').default;
var today = new Date();
var todayLocale = new Date().toLocaleString('nl-NL', {  year: 'numeric', month: 'long', day: 'numeric' })

export default {
  name: 'radioGuide',
  data() {
    return {
        radioGuideTwenteFMFromApi: null,
        currentDate: ("0" + today.getDate()).slice(-2)+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+today.getFullYear(),
        currentDayLocale: todayLocale
    }
  },
  mounted() {
    axios
    .post('https://prod-api.1twente.nl/epg/radio/twentefm/today')
    .then(response => (this.radioGuideTwenteFMFromApi = response.data.solspace_calendar.calendar.events))
    .catch(error => {
      console.log(error)
      this.errored = true
    })
  },
}
</script>

<style scoped>
.slide__title {
    position: absolute;
    width: 1580px;
    height: 88px;
    left: 169px;
    top: 140px;
    z-index: 2;
    text-align: center;
}
.slide__title h1 {
    font-weight: bold;
    font-size: 60px;
    letter-spacing: -0.01em;
    color: #FFF;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.slide__date {
    position: absolute;
    width: 220px;
    height: 50px;
    left: 860px;
    top: 120px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
}
.slide__date span {
    font-family: DIN 2014;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFF;
}
.slide__body {
    position: absolute;
    top: 275px;
    left: 610px;
    right: 300px;
    display: flex;
    width: 1320px;
    height: 650px;
    overflow: hidden;
}
.slide__station {
    position: relative;
    width: 700px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
}
.slide__station:first-child {
    margin-right: 80px;
}
.slide__station--title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
    margin-bottom: 20px;
    background: #be1c2e;
    border-radius: 10px 10px 0 0;
    font-family: DIN-2014;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    color: #fff;
}
.slide__station--title span {
    font-size: 14px;
    margin-left: 10px;
}
.slide__station:before {
    content: "";
    position: absolute;
    top: 75px;
    left: 0;
    height: calc(100% - 75px);
    width: 155px;
    background: #BDBDBD;
    opacity: 0.35;
    border-radius: 0 0 0 10px;
}
.slide__program {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 40px;
}
.slide__program--time {
    width: 90px;
    font-family: DIN-2014;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: right;
    color: #373737;
    opacity: 0.7;
}
.slide__program--title {
    padding: 0 0 0 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #373737;
    line-height: 1;
}
</style>
