<template>
  <div id="app" class="container">
    <div class="slides">
      <!-- load tv guide slide -->
      <!-- <tvGuide /> -->

      <!-- load radio guide slide -->
      <radioGuide />

      <!-- load news articles slides -->
      <newsArticles />
    </div>
  </div>
  <div class="logo-bug">
    <figure>
      <img src="https://media-cdn.1twente.nl/Assets/twentetv_logobug.png"/>
    </figure>
  </div>
</template>

<script>
const axios = require('axios').default;
// import $ from 'jquery';
import gsap from "gsap";
// import tvGuide from '@/components/tvGuide.vue'
import radioGuide from '@/components/radioGuide.vue'
import newsArticles from '@/components/newsArticles.vue'

export default {
  name: 'app',
  data() {
    return {
    }
  },
  components: {
    // tvGuide,
    radioGuide,
    newsArticles
  },
  mounted() {
    axios
    .get('https://api.1twente.nl/api/articles')
    .then(response => (this.articlesFromApi = response.data.data.entries))
    .catch(error => {
      console.log(error)
      this.errored = true
    })
    .finally(() => {
      let animation = gsap.timeline({repeat:-1})
      
      function init() {
        gsap.set(".slide", {autoAlpha:1})
        animation.from(".slide", {opacity:0, stagger:25}) 
                 .to(".slide", {opacity:0, stagger:25}, 25)
      }

      init()
    })
  }
}
</script>

<style>
@import url("https://use.typekit.net/tww3aky.css");
@import url("https://use.typekit.net/rzw1ozh.css");
* {
  margin: 0;
  padding: 0;
}
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(39, 39, 39, 0.2)
}
.logo-bug {
  position: fixed;
  top: 0;
  left: 0;
  widows: 1920px;
  height: 1080px;
  z-index: 9;
  pointer-events: none;
}
.slide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: calc(100vw - 200px);
  height: calc(100vh - 100px);
}
.slide:first-child {
  opacity: 1;
}
.slide__image {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 456px;
}
.slide__image figure {
  width: 100vw;
  height: 456px;
}
.slide__image figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slide__image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0.12) 0%, rgba(30, 30, 30, 0) 22.92%, rgba(30, 30, 30, 0) 37.03%, rgba(30, 30, 30, 0.8) 65.76%);
}
.slide__image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1E1E1E;
  opacity: 0.3;
}
</style>
