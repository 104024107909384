<template>
  <div class="slide slide--article" v-for="article in articles" :key="article.id">
    <div class="slide__image">
      <figure v-for="photo in article.imageThumbnail" :key="photo.url">
        <img v-bind:src="photo.url" alt="">
      </figure>
      <div class="slide__title">
        <h1>{{ article.title }}</h1>
      </div>
      <div class="slide__meta">
        <span v-if="article.category[0].title">{{ article.category[0].title }}</span>
        <span v-else>Twente</span>
      </div>
    </div>

    <div class="slide__body">
      <div class="slide__content">
        <b v-if="article.textTVContent" v-html="article.textTVContent"></b>
        <p v-else v-html="article.intro"></p>
      </div>
      <div class="slide__qr">
        <div class="slide__qr--inner">
          <div class="slide__qr--meta">
            Scan de QR code <br><br>
            Of lees verder op <br>
            twentefm.nl
          </div>
          <figure>
            <qrcode-vue :value="article.url + '&utm_medium=text-tv-qr-code'" :size="250" level="H" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';

import QrcodeVue from 'qrcode.vue'

export default {
  name: 'newsArticles',
  data() {
    return {
      articles: null,
      loading: false
    };
  },
  components: {
    QrcodeVue
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      const token = 'zx2Zt1cb5374zgGUf5QtxiBktKtCyNKl';

      axios
        .post('https://1twente.nl/graphql', {
          query: gql`
            query getArticlesTextTVNOT {
              entries(
                limit: 15
                relatedTo: ["or", 377000, 1904450]
                orderBy: "postDate DESC"
                showOntextTV: true
              ) {
                ... on articles_articles_Entry {
                  id
                  title
                  url
                  postDate
                  dateUpdated
                  typeArticle
                  colabWithRTVOost
                  imageThumbnail: photoGeneral {
                    url(transform: "bigThumbnails")
                  }
                  author {
                    fullName
                  }
                  intro: introText
                  category {
                    title
                  }
                }
              }
            }
          `
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          this.articles = response.data.data.entries;
          console.log(this.articles)
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.slide--article {}

.slide__title {
  position: absolute;
  top: 200px;
  left: 169px;
  right: 169px;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 140px;
}

.slide__title h1 {
  position: relative;
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
  line-height: 68px;
  color: #fff;
}

.slide__title h1:before {
  content: "";
  position: absolute;
  top: 10%;
  left: -30px;
  width: 15px;
  height: 80%;
  background-color: #be1c2e;
  border-radius: 10px;
}

.slide__meta {
  position: absolute;
  top: 140px;
  right: 142px;
  z-index: 2;
  text-align: right;
  font-family: DIN-2014;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #FFF;
}

.slide__body {
  position: absolute;
  top: 375px;
  left: 94px;
  right: 94px;
  width: 1730px;
  height: 550px;
  margin: 0 auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.slide__content {
  width: 1273px;
  padding: 40px 75px;
}

.slide__content b,
p,
a {
  font-family: DIN 2014;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 48px;
  text-decoration: none !important;
  color: #333 !important;
}

.slide__content a {
  color: #333;
  text-decoration: none;
}

.slide__qr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 457px;
  background: linear-gradient(180deg, rgba(180, 180, 180, 15%) 21.3%, #BDBDBD 80.73%);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0px 10px 10px 0px;
}

.slide__qr--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 509px;
  border-radius: 10px;
  background: #fff;
}

.slide__qr--inner figure {
  width: 250px;
  height: 250px;
}

.slide__qr--meta {
  margin-bottom: 50px;
  font-family: DIN 2014;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #be1c2e;
}
</style>
